import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  ToggleButton,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DatePicker, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop';
import { useTranslation } from 'react-i18next';
import '../styles/global.css';
import { NumericFormat } from 'react-number-format';
import { useAuth0 } from '@auth0/auth0-react'; // Import Auth0
import { format } from 'date-fns'; // Import date-fns for date formatting

const TransactionSpecifics = ({ user }) => {
  const { t } = useTranslation();
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0(); // Get user from Auth0
  const [transactionType, setTransactionType] = useState(propertyData.transaction_type || '');
  const [propertyType, setPropertyType] = useState(propertyData.property_type || '');
  const [annualTaxes, setAnnualTaxes] = useState(propertyData.annual_taxes || '');
  const [totalMonthlyExpenses, setTotalMonthlyExpenses] = useState(propertyData.total_monthly_expenses || '');
  const [securityDeposit, setSecurityDeposit] = useState(propertyData.security_deposit || '');
  const [moveInReady, setMoveInReady] = useState(propertyData.move_in_ready || false);
  const [moveInDate, setMoveInDate] = useState(propertyData.move_in_date ? new Date(propertyData.move_in_date) : new Date());
  const [fireSale, setFireSale] = useState(propertyData.fire_sale || false);
  const [realEstateExchangeOption, setRealEstateExchangeOption] = useState(propertyData.real_estate_exchange_option || false);
  const [withExistingTenant, setWithExistingTenant] = useState(propertyData.with_existing_tenant || false);
  const [availableForVideoTour, setAvailableForVideoTour] = useState(propertyData.available_for_video_tour || false);

  const [childFriendly, setChildFriendly] = useState(propertyData.child_friendly || false);
  const [coupleFriendly, setCoupleFriendly] = useState(propertyData.couple_friendly || false);
  const [familyFriendly, setFamilyFriendly] = useState(propertyData.family_friendly || false);
  const [foreignerFriendly, setForeignerFriendly] = useState(propertyData.foreigner_friendly || false);
  const [petFriendly, setPetFriendly] = useState(propertyData.pet_friendly || false);
  const [smokingAllowed, setSmokingAllowed] = useState(propertyData.smoking_allowed || false);
  const [studentFriendly, setStudentFriendly] = useState(propertyData.student_friendly || false);
  const [suitableForOfficeSpace, setSuitableForOfficeSpace] = useState(propertyData.suitable_for_office_space || false);
  const [workerFriendly, setWorkerFriendly] = useState(propertyData.worker_friendly || false);

  const [lessorSpeaksChinese, setLessorSpeaksChinese] = useState(propertyData.lessor_speaks_chinese || false);
  const [lessorSpeaksEnglish, setLessorSpeaksEnglish] = useState(propertyData.lessor_speaks_english || false);
  const [lessorSpeaksFrench, setLessorSpeaksFrench] = useState(propertyData.lessor_speaks_french || false);
  const [lessorSpeaksGerman, setLessorSpeaksGerman] = useState(propertyData.lessor_speaks_german || false);
  const [lessorSpeaksHungarian, setLessorSpeaksHungarian] = useState(propertyData.lessor_speaks_hungarian || false);
  const [lessorSpeaksOtherLanguages, setLessorSpeaksOtherLanguages] = useState(propertyData.lessor_speaks_other_languages || false);
  const [lessorSpeaksRussian, setLessorSpeaksRussian] = useState(propertyData.lessor_speaks_russian || false);
  const [lessorSpeaksSerbian, setLessorSpeaksSerbian] = useState(propertyData.lessor_speaks_serbian || false);
  const [lessorSpeaksSpanish, setLessorSpeaksSpanish] = useState(propertyData.lessor_speaks_spanish || false);

  const [minimumLeaseTerm, setMinimumLeaseTerm] = useState(propertyData.minimum_lease_term || '');
  const [rentPaymentFrequency, setRentPaymentFrequency] = useState(propertyData.rent_payment_frequency || '');
  const [fixedPrice, setFixedPrice] = useState(propertyData.fixed_price || false);

  const [currency, setCurrency] = useState('EUR');
  const [price, setPrice] = useState('');

  const [adPostedDate, setAdPostedDate] = useState(propertyData.ad_posted_date ? new Date(propertyData.ad_posted_date) : new Date());
  const [adLastUpdatedDate, setAdLastUpdatedDate] = useState(propertyData.ad_last_updated_date ? new Date(propertyData.ad_last_updated_date) : new Date());

  useEffect(() => {
    if (user && user.email) {
      updatePropertyData({ ad_contributor: user.email });
    }
  }, [user, updatePropertyData]);

  useEffect(() => {
    const updateCurrency = () => {
      if (annualTaxes) updatePropertyData({ annual_taxes_currency: currency });
      if (totalMonthlyExpenses) updatePropertyData({ total_monthly_expenses_currency: currency });
      if (securityDeposit) updatePropertyData({ security_deposit_currency: currency });
    };
    updateCurrency();
  }, [currency, annualTaxes, totalMonthlyExpenses, securityDeposit]);

  useEffect(() => {
    if (transactionType === 'For Sale') {
      setPrice(propertyData.asking_price || '');
    } else if (transactionType === 'For Rent') {
      setPrice(propertyData.rental_price || '');
    }
  }, [transactionType, propertyData.asking_price, propertyData.rental_price]);

  useEffect(() => {
    // Set default dates in data context on component mount
    updatePropertyData({
      move_in_date: format(moveInDate, 'yyyy-MM-dd HH:mm:ss'),
      ad_posted_date: format(adPostedDate, 'yyyy-MM-dd HH:mm:ss'),
      ad_last_updated_date: format(adLastUpdatedDate, 'yyyy-MM-dd HH:mm:ss'),
    });
  }, []);

  const handleInputChange = (field, value) => {
    if (field === 'move_in_date' || field === 'ad_posted_date' || field === 'ad_last_updated_date') {
      value = value ? format(value, 'yyyy-MM-dd HH:mm:ss') : '';
    }
    updatePropertyData({ [field]: value });

    switch (field) {
      case 'transaction_type':
        setTransactionType(value);
        break;
      case 'property_type':
        setPropertyType(value);
        break;
      case 'annual_taxes':
        setAnnualTaxes(value);
        break;
      case 'total_monthly_expenses':
        setTotalMonthlyExpenses(value);
        break;
      case 'security_deposit':
        setSecurityDeposit(value);
        break;
      case 'move_in_ready':
        setMoveInReady(value);
        break;
      case 'move_in_date':
        setMoveInDate(value);
        break;
      case 'fire_sale':
        setFireSale(value);
        break;
      case 'real_estate_exchange_option':
        setRealEstateExchangeOption(value);
        break;
      case 'with_existing_tenant':
        setWithExistingTenant(value);
        break;
      case 'available_for_video_tour':
        setAvailableForVideoTour(value);
        break;
      case 'child_friendly':
        setChildFriendly(value);
        break;
      case 'couple_friendly':
        setCoupleFriendly(value);
        break;
      case 'family_friendly':
        setFamilyFriendly(value);
        break;
      case 'foreigner_friendly':
        setForeignerFriendly(value);
        break;
      case 'pet_friendly':
        setPetFriendly(value);
        break;
      case 'smoking_allowed':
        setSmokingAllowed(value);
        break;
      case 'student_friendly':
        setStudentFriendly(value);
        break;
      case 'suitable_for_office_space':
        setSuitableForOfficeSpace(value);
        break;
      case 'worker_friendly':
        setWorkerFriendly(value);
        break;
      case 'lessor_speaks_chinese':
        setLessorSpeaksChinese(value);
        break;
      case 'lessor_speaks_english':
        setLessorSpeaksEnglish(value);
        break;
      case 'lessor_speaks_french':
        setLessorSpeaksFrench(value);
        break;
      case 'lessor_speaks_german':
        setLessorSpeaksGerman(value);
        break;
      case 'lessor_speaks_hungarian':
        setLessorSpeaksHungarian(value);
        break;
      case 'lessor_speaks_other_languages':
        setLessorSpeaksOtherLanguages(value);
        break;
      case 'lessor_speaks_russian':
        setLessorSpeaksRussian(value);
        break;
      case 'lessor_speaks_serbian':
        setLessorSpeaksSerbian(value);
        break;
      case 'lessor_speaks_spanish':
        setLessorSpeaksSpanish(value);
        break;
      case 'minimum_lease_term':
        setMinimumLeaseTerm(value);
        break;
      case 'rent_payment_frequency':
        setRentPaymentFrequency(value);
        break;
      case 'fixed_price':
        setFixedPrice(value);
        break;
      case 'ad_posted_date':
        setAdPostedDate(value);
        break;
      case 'ad_last_updated_date':
        setAdLastUpdatedDate(value);
        break;
      default:
        break;
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handlePriceChange = (values) => {
    const { value } = values;
    setPrice(value);
    if (transactionType === 'For Sale') {
      updatePropertyData({
        asking_price: value,
        asking_price_currency: currency,
        ad_contributor: auth0User.email, // Set ad_contributor when price is input
      });
    } else if (transactionType === 'For Rent') {
      updatePropertyData({
        rental_price: value,
        rental_price_currency: currency,
        ad_contributor: auth0User.email, // Set ad_contributor when price is input
      });
    }
  };

  const calculatePricePerUnitOfArea = () => {
    const propertyArea = propertyData.property_area;
    if (propertyArea && propertyArea > 0) {
      if (transactionType === 'For Sale' && propertyData.asking_price) {
        const pricePerUnitOfArea = propertyData.asking_price / propertyArea;
        updatePropertyData({ asking_price_per_unit_of_area: pricePerUnitOfArea });
      } else if (transactionType === 'For Rent' && propertyData.rental_price) {
        const pricePerUnitOfArea = propertyData.rental_price / propertyArea;
        updatePropertyData({ rental_price_per_unit_of_area: pricePerUnitOfArea });
      }
    }
  };

  useEffect(() => {
    calculatePricePerUnitOfArea();
  }, [propertyData.asking_price, propertyData.rental_price, propertyData.property_area]);

  const handleNextClick = () => {
    calculatePricePerUnitOfArea();
    navigate('/valuation'); // Adjust the route to the next page
  };

  const renderDay = (day, selectedDate, DayComponentProps) => {
    const { key, ...rest } = DayComponentProps;
    return <PickersDay {...rest} key={day.toString()} />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="md" className="container">
        <ScrollToTop /> {/* Scroll to top on component load */}
        <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>
          {t('transactionSpecifics.back')}
        </Button>

        <Box className="mt-4">
          <Typography variant="subtitle1">{t('transactionSpecifics.currencySubtitle')}</Typography>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{t('transactionSpecifics.currency')}</InputLabel>
                <Select
                  value={currency}
                  onChange={handleCurrencyChange}
                  label={t('transactionSpecifics.currency')}
                  fullWidth
                >
                  {['RSD', 'USD', 'EUR', 'GBP', 'CAD', 'AUD', 'JPY', 'CNY', 'INR', 'BRL', 'ZAR'].map((curr) => (
                    <MenuItem value={curr} key={curr}>{curr}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {auth0User && auth0User.email === 'i.am.nemamiah@gmail.com' && (
          <Box className="mt-4">
            <Typography variant="subtitle1">
              {transactionType === 'For Sale' ? t('transactionSpecifics.askingPrice') : t('transactionSpecifics.rentalPrice')}
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={6} sm={4}>
                <NumericFormat
                  customInput={TextField}
                  label={transactionType === 'For Sale' ? t('transactionSpecifics.askingPrice') : t('transactionSpecifics.rentalPrice')}
                  type="text"
                  fullWidth
                  value={price}
                  onValueChange={handlePriceChange}
                  margin="normal"
                  thousandSeparator={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                  }}
                  sx={{ marginTop: '16px' }} // Increase the top margin
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {transactionType === 'For Rent' && (
          <>
            <Box className="mt-4">
              <Typography variant="subtitle1">{t('transactionSpecifics.rentPaymentFrequency')}</Typography>
              <Grid container spacing={1} justifyContent="center">
                {['Monthly', 'Every 3 Months', 'Every 6 Months', 'Annually', 'Daily'].map((freq) => (
                  <Grid item key={freq}>
                    <ToggleButton
                      value={freq}
                      selected={rentPaymentFrequency === freq}
                      onChange={() => handleInputChange('rent_payment_frequency', freq)}
                    >
                      {t(`transactionSpecifics.rentPaymentFrequencies.${freq}`)}
                    </ToggleButton>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box className="mt-4">
              <Typography variant="subtitle1">{t('transactionSpecifics.minimumLeaseTerm')}</Typography>
              <Grid container spacing={1} justifyContent="center">
                {['Daily', '1 month', '3 months', '6 months', '12 months', '2 years', '3 years', '4 years', '5+ years'].map((term) => (
                  <Grid item key={term}>
                    <ToggleButton
                      value={term}
                      selected={minimumLeaseTerm === term}
                      onChange={() => handleInputChange('minimum_lease_term', term)}
                    >
                      {t(`transactionSpecifics.minimumLeaseTerms.${term}`)}
                    </ToggleButton>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}

        {transactionType === 'For Sale' && (
          <Box className="mt-4">
            <Typography variant="subtitle1">{t('transactionSpecifics.annualTaxes')}</Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={6} sm={4}>
                <NumericFormat
                  customInput={TextField}
                  label={t('transactionSpecifics.annualTaxes')}
                  type="text"
                  fullWidth
                  value={annualTaxes}
                  onValueChange={(values) => handleInputChange('annual_taxes', values.value)}
                  margin="normal"
                  thousandSeparator={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                  }}
                  sx={{ marginTop: '16px' }} // Increase the top margin
                />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box className="mt-4">
          <Typography variant="subtitle1">{t('transactionSpecifics.totalMonthlyExpenses')}</Typography>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={6} sm={4}>
              <NumericFormat
                customInput={TextField}
                label={t('transactionSpecifics.totalMonthlyExpenses')}
                type="text"
                fullWidth
                value={totalMonthlyExpenses}
                onValueChange={(values) => handleInputChange('total_monthly_expenses', values.value)}
                margin="normal"
                thousandSeparator={true}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                }}
                sx={{ marginTop: '16px' }} // Increase the top margin
              />
            </Grid>
          </Grid>
        </Box>

        {transactionType === 'For Rent' && (
          <Box className="mt-4">
            <Typography variant="subtitle1">{t('transactionSpecifics.securityDeposit')}</Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={6} sm={4}>
                <NumericFormat
                  customInput={TextField}
                  label={t('transactionSpecifics.securityDeposit')}
                  type="text"
                  fullWidth
                  value={securityDeposit}
                  onValueChange={(values) => handleInputChange('security_deposit', values.value)}
                  margin="normal"
                  thousandSeparator={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                  }}
                  sx={{ marginTop: '16px' }} // Increase the top margin
                />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box className="mt-4">
          <Typography variant="subtitle1">{t('transactionSpecifics.adPostedDate')}</Typography>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <DatePicker
                label={t('transactionSpecifics.adPostedDate')}
                value={adPostedDate}
                onChange={(date) => {
                  if (date && date !== adPostedDate) {
                    handleInputChange('ad_posted_date', date);
                    setAdPostedDate(date);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    sx={{ marginTop: '16px' }} // Increase the top margin
                  />
                )}
                renderDay={renderDay}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-4">
          <Typography variant="subtitle1">{t('transactionSpecifics.adLastUpdatedDate')}</Typography>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <DatePicker
                label={t('transactionSpecifics.adLastUpdatedDate')}
                value={adLastUpdatedDate}
                onChange={(date) => {
                  if (date && date !== adLastUpdatedDate) {
                    handleInputChange('ad_last_updated_date', date);
                    setAdLastUpdatedDate(date);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    sx={{ marginTop: '16px' }} // Increase the top margin
                  />
                )}
                renderDay={renderDay}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-4">
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={moveInReady}
                    onChange={(e) => handleInputChange('move_in_ready', e.target.checked)}
                    color="primary"
                  />
                }
                label={t('transactionSpecifics.moveInReady')}
              />
            </Grid>
            {!moveInReady && (
              <Grid item>
                <DatePicker
                  label={t('transactionSpecifics.moveInDate')}
                  value={moveInDate}
                  onChange={(date) => {
                    if (date && date !== moveInDate) {
                      handleInputChange('move_in_date', date);
                      setMoveInDate(date);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      sx={{ marginTop: '16px' }} // Increase the top margin
                    />
                  )}
                  renderDay={renderDay}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {transactionType === 'For Sale' && (
          <Box className="mt-4">
            <Typography variant="subtitle1">{t('transactionSpecifics.saleOptions')}</Typography>
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={fireSale}
                      onChange={(e) => handleInputChange('fire_sale', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={t('transactionSpecifics.fireSale')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={realEstateExchangeOption}
                      onChange={(e) => handleInputChange('real_estate_exchange_option', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={t('transactionSpecifics.realEstateExchangeOption')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={withExistingTenant}
                      onChange={(e) => handleInputChange('with_existing_tenant', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={t('transactionSpecifics.withExistingTenant')}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {(propertyType === 'Apartment' || propertyType === 'House') && transactionType === 'For Rent' && (
          <>
            <Box className="mt-4">
              <Typography variant="subtitle1">{t('transactionSpecifics.lessorsPreferences')}</Typography>
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <ToggleButton
                    value="childFriendly"
                    selected={childFriendly}
                    onClick={() => handleInputChange('child_friendly', !childFriendly)}
                  >
                    {t('transactionSpecifics.childFriendly')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="coupleFriendly"
                    selected={coupleFriendly}
                    onClick={() => handleInputChange('couple_friendly', !coupleFriendly)}
                  >
                    {t('transactionSpecifics.coupleFriendly')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="familyFriendly"
                    selected={familyFriendly}
                    onClick={() => handleInputChange('family_friendly', !familyFriendly)}
                  >
                    {t('transactionSpecifics.familyFriendly')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="foreignerFriendly"
                    selected={foreignerFriendly}
                    onClick={() => handleInputChange('foreigner_friendly', !foreignerFriendly)}
                  >
                    {t('transactionSpecifics.foreignerFriendly')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="petFriendly"
                    selected={petFriendly}
                    onClick={() => handleInputChange('pet_friendly', !petFriendly)}
                  >
                    {t('transactionSpecifics.petFriendly')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="smokingAllowed"
                    selected={smokingAllowed}
                    onClick={() => handleInputChange('smoking_allowed', !smokingAllowed)}
                  >
                    {t('transactionSpecifics.smokingAllowed')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="studentFriendly"
                    selected={studentFriendly}
                    onClick={() => handleInputChange('student_friendly', !studentFriendly)}
                  >
                    {t('transactionSpecifics.studentFriendly')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="suitableForOfficeSpace"
                    selected={suitableForOfficeSpace}
                    onClick={() => handleInputChange('suitable_for_office_space', !suitableForOfficeSpace)}
                  >
                    {t('transactionSpecifics.suitableForOfficeSpace')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="workerFriendly"
                    selected={workerFriendly}
                    onClick={() => handleInputChange('worker_friendly', !workerFriendly)}
                  >
                    {t('transactionSpecifics.workerFriendly')}
                  </ToggleButton>
                </Grid>
              </Grid>
            </Box>

            <Box className="mt-4">
              <Typography variant="subtitle1">{t('transactionSpecifics.lessorSpeaks')}</Typography>
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksChinese"
                    selected={lessorSpeaksChinese}
                    onClick={() => handleInputChange('lessor_speaks_chinese', !lessorSpeaksChinese)}
                  >
                    {t('transactionSpecifics.lessorSpeaksChinese')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksEnglish"
                    selected={lessorSpeaksEnglish}
                    onClick={() => handleInputChange('lessor_speaks_english', !lessorSpeaksEnglish)}
                  >
                    {t('transactionSpecifics.lessorSpeaksEnglish')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksFrench"
                    selected={lessorSpeaksFrench}
                    onClick={() => handleInputChange('lessor_speaks_french', !lessorSpeaksFrench)}
                  >
                    {t('transactionSpecifics.lessorSpeaksFrench')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksGerman"
                    selected={lessorSpeaksGerman}
                    onClick={() => handleInputChange('lessor_speaks_german', !lessorSpeaksGerman)}
                  >
                    {t('transactionSpecifics.lessorSpeaksGerman')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksHungarian"
                    selected={lessorSpeaksHungarian}
                    onClick={() => handleInputChange('lessor_speaks_hungarian', !lessorSpeaksHungarian)}
                  >
                    {t('transactionSpecifics.lessorSpeaksHungarian')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksOtherLanguages"
                    selected={lessorSpeaksOtherLanguages}
                    onClick={() => handleInputChange('lessor_speaks_other_languages', !lessorSpeaksOtherLanguages)}
                  >
                    {t('transactionSpecifics.lessorSpeaksOtherLanguages')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksRussian"
                    selected={lessorSpeaksRussian}
                    onClick={() => handleInputChange('lessor_speaks_russian', !lessorSpeaksRussian)}
                  >
                    {t('transactionSpecifics.lessorSpeaksRussian')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksSerbian"
                    selected={lessorSpeaksSerbian}
                    onClick={() => handleInputChange('lessor_speaks_serbian', !lessorSpeaksSerbian)}
                  >
                    {t('transactionSpecifics.lessorSpeaksSerbian')}
                  </ToggleButton>
                </Grid>
                <Grid item>
                  <ToggleButton
                    value="lessorSpeaksSpanish"
                    selected={lessorSpeaksSpanish}
                    onClick={() => handleInputChange('lessor_speaks_spanish', !lessorSpeaksSpanish)}
                  >
                    {t('transactionSpecifics.lessorSpeaksSpanish')}
                  </ToggleButton>
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        <Box className="mt-4">
          <Typography variant="subtitle1">{t('transactionSpecifics.miscellaneousOptions')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={availableForVideoTour}
                    onChange={(e) => handleInputChange('available_for_video_tour', e.target.checked)}
                    color="primary"
                  />
                }
                label={t('transactionSpecifics.availableForVideoTour')}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={fixedPrice}
                    onChange={(e) => handleInputChange('fixed_price', e.target.checked)}
                    color="primary"
                  />
                }
                label={t('transactionSpecifics.fixedPrice')}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={4} textAlign="center">
          <Button variant="contained" color="primary" onClick={handleNextClick}>
            {t('transactionSpecifics.next')}
          </Button>
        </Box>
      </Container>
    </LocalizationProvider>
  );
};

export default TransactionSpecifics;